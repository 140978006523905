import React, { useCallback, useState, useEffect } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import useOnClickOutside from "./useOutside";
// import DatePanel from "react-multi-date-picker/plugins/date_panel";

const DatePickerCmp = ({
      id,
      name, 
      dateRangeValue,
      fromDate,
      toDate,
      onChangeDateTimePicker
    }) => {
  const dateTimeRef = React.useRef();

  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );

  // if commented this line component TimePicker don't be closed on click anywhere
  useOnClickOutside(dateTimeRef, handleDatePickerClose);

//   const [dates, setDates] = useState([
//     new DateObject(fromDate),
//     new DateObject(toDate),
//  ]);

const [dates, setDates] = useState([]);

 useEffect(() => {
  setDates( [
     new DateObject(fromDate),
      new DateObject(toDate),
   ] );

}, []);


  return (
    <div>

{/* fromDate:  {fromDate}
<br/>
toDate:  {toDate}
<br/>

dates: 
    {JSON.stringify(dates)} */}
    
         <DatePicker
        id= {id}
        name = {name}
        value={fromDate !=null && toDate != null ? dates : "" }
        ref={dateTimeRef}  
        shouldCloseOnSelect
        weekStartDayIndex={1}
        // plugins={[<TimePicker position="bottom" />]}
        range
        numberOfMonths={1}
        rangeHover
        format="DD/MMM/YYYY"
        placeholder=" "
        dateSeparator=" to "
        //onChange={(e) => onChangeDateTimePicker(id, e)}

        onChange={(date) => {
          onChangeDateTimePicker(id,date);

          if(date != null)
          {
            const answer_array = date.toString().split(',');
            var FromDate = answer_array[0];
             var ToDate = answer_array[1];

            setDates([FromDate , ToDate]);
          }
          else
          {
            setDates([]);
          }
         
        }}


        //onChangeHandller = {alert('AA')}
      />
{/* startDate: {startDate} */}
              
      {/* <DatePicker
        multiple
        sort
        onFocusedDateChange={setFocusedDate}
        onClose={() => setFocusedDate(undefined)}
        plugins={[<DatePanel markFocused focusedClassName="bg-red" />]}
        mapDays={({ date, isSameDate }) => {
          let props = {};

          if (!isSameDate(date, focusedDate)) return;

          props.style = { backgroundColor: "red" };

          return props;
        }}
        ref={dateTimeRef}
      /> */}
    </div>
  );
};

export default DatePickerCmp;
