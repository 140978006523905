import React from "react";
import { useState } from "react";
import { Input, Tooltip, Row, Col,  Card, CardBody, CardHeader  } from "reactstrap";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragIcon from "../../assets/images/drag.png";
import DatePickerCmp from "../../common/DatePickerCmp";
import RequiredError from "../../common/RequiredError";

const PackageDatesDynamic = ({
  handleAddPackageDates,
  packageDatesList,
  onChangeDateTimePicker,
  onChangePackageDatesInputs,
  showInvalid,
  removePackageDatesHandller,
  toggleArrowTab,
  activeArrowTab,
  modalSaveHandller,
  cancelHandller,
  handleDragEndpackageDates
}) => {
  const [tooltipOpenInclusion, setToolTipOpenInclusion] = useState(false);

  const arr = [];

  const dateTimeRef = React.useRef();

  const test = (date) => {
    arr.push(date);
  };

  const [focusedDate, setFocusedDate] = useState();

  return (
    <>
      <Row>
              <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex bg-light">
                      <h4 className="card-title mb-0">Package Dates</h4>
                    </CardHeader>
                    <CardBody>
                      <Row>
                      <Col xxl={12} className="cardItems">

                        <div>
                          <label htmlFor="add_packageDates" className="form-label">
                            Add Package Date{" "}
                            <i
                              className="ri-add-line align-bottom mx-2"
                              onClick={handleAddPackageDates}
                              id="package_packageDates"
                              style={{
                                padding: "3px",
                                // marginTop: "10px",
                                fontSize: "14px",
                                borderRadius: "50%",
                                backgroundColor: "#099885",
                                color: "white",
                                cursor: "pointer",
                              }}
                            ></i>
                            <Tooltip
                              isOpen={tooltipOpenInclusion}
                              placement="right"
                              target="package_packageDates"
                              toggle={() => {
                                setToolTipOpenInclusion(!tooltipOpenInclusion);
                              }}
                            >
                              Add Package Date
                            </Tooltip>
                          </label>
                        </div>
                        <div className="m-3">
             
                          <div className="table-responsive table-card">

                            <DragDropContext onDragEnd={handleDragEndpackageDates}>
                                <table
                                  className="table align-middle table-nowrap"
                                  id="customerTable"
                                >
                                  <thead className="table-light">
                                      <tr>
                                      <th className="" width={100} >Drag</th>
                                      <th className="" width={100} >S.No.</th>
                                      <th className="" width={400}>Date(s)</th>
                                      <th className="" width={400} >Remarks</th>
                                      <th className="" width={200} >Package Price</th>
                                      <th className="" width={100}>Remove</th>
                                    </tr>
                                  </thead>


                                 {/* "packageDatesList"   {JSON.stringify(packageDatesList)} */}

                                  <Droppable droppableId="droppable-1">
                                    {(provider) => (
                                        <tbody
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                         >                                
                                          {packageDatesList?.length > 0 &&
                                            packageDatesList?.map((item, index) => (

                                            <Draggable
                                              key={`dragableId-${index}`}
                                              draggableId={`dragableId-${index}`}
                                              index={index}
                                              type="TASK"
                                            >
                                              {(provider) => (
                                                <tr
                                                  key={index}
                                                  {...provider.draggableProps}
                                                  {...provider.dragHandleProps}
                                                  ref={provider.innerRef}
                                                >
                                                  <td
                                                    {...provider.dragHandleProps}
                                                   
                                                  >
                                                    <img
                                                      src={dragIcon}
                                                      alt="dragIcon"
                                                      style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        opacity: "0.7",
                                                      }}
                                                    />
                                                  </td>
                                                  <td> {index + 1}</td>
                                                  <td>  
                                                    
                                                    <DatePickerCmp 
                                                      id= {item.id}
                                                      name = {"packageDateRange"}
                                                      fromDate= {item?.packageFromDate}
                                                      toDate= {item?.packageToDate}
                                                      dateRangeValue= {item?.packageDateRange}
                                                      onChangeDateTimePicker={onChangeDateTimePicker}    
                                                       />
                                                     
                                                   </td>  
                                                   <td> 
                                                    
                                                        <Input
                                                          type="text"
                                                          className="form-control"
                                                          name="packageRemarks"
                                                          id = {item?.id}
                                                          value={item?.packageRemarks}
                                                          defaultValue={item?.packageRemarks}
                                                          onChange={(e) => onChangePackageDatesInputs(e)}
                                                         // invalid={item?.packageRemarks?.length < 1 && showInvalid}
                                                         // required
                                                        />
                                                          {/*                                                  
                                                          {item?.packageRemarks?.length < 1 && showInvalid ? (
                                                            <RequiredError errorMessage={" Remarks is Required"} />
                                                          ) : (
                                                            ""
                                                          )} */}


                                                   </td>
                                                   <td> 
                                                       <Input
                                                          type="number"
                                                          className="form-control"
                                                          name="packagePrice"
                                                          id = {item?.id}
                                                          value={item?.packagePrice}
                                                          defaultValue={item?.packagePrice}
                                                          onChange={(e) => onChangePackageDatesInputs(e)}
                                                          //invalid={item?.packagePrice?.length < 1 && showInvalid}
                                                          //required
                                                        />

                                                   </td>

                                                   <td>
                                                    <i
                                                      className="ri-close-line"
                                                      onClick={() => removePackageDatesHandller(item.id)}
                                                      style={{
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </td>
                                                </tr>
                                              )}
                                             </Draggable>
                                           ))}
                                          {provider.placeholder}
                                        </tbody>
                                      )}
                                    </Droppable>
                                  </table>
                            </DragDropContext>

                          </div>
                        </div>
                      </Col>
                      </Row>
                    </CardBody>
                  </Card>
              </Col>             
      </Row>       
   

      <div className="d-flex justify-content-xl-between">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default PackageDatesDynamic;
